<template>
  <div>
    <article>
      <section>
        <div class="payment_dialogs">
          <el-dialog
            :title="`Placing a Booking of a ${occupancyData.categoryName}`"
            :visible.sync="isEditBannerModalVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
          
            <div v-if="isShowPaymentIframe" class="iframe-container">
              <iframe :src="paymentIframeLink"></iframe>
            </div>

            <div v-else>

              <div v-loading="isLoading">
                <article class="d-flex align-items-start justify-content-between mb-5">
                  <h2 class="mb-4">
                    Total Amount:
                    <strong>{{ formatNumber( convertedAmount.toFixed(2)) }}</strong> 
                    {{ selectedCurrency }}
                  </h2>
                  
                  <div class="box">
                    <h2>Choose Currency</h2>
                    <el-select
                      v-model="selectedCurrency"
                      placeholder="Select"
                      size="mini"
                      filterable
                      @change="chooseCurrency"
                    >
                      <el-option
                        v-for="(rate, currency) in exchangeRates"
                        :key="currency"
                        :label="currency"
                        :value="currency"
                      >
                      </el-option>
                    </el-select>
                    <!-- <p v-if="convertedAmount !== null">
                      {{ totalAmount }} UGX = <strong>{{ convertedAmount.toFixed(2) }}</strong> {{ selectedCurrency }}
                    </p> -->
                  </div>
                </article>

                <!-- <div class="currency-converter">
                  <h2>Choose Currency</h2>
                  <select v-model="selectedCurrency" id="currency">
                    <option v-for="(rate, currency) in exchangeRates" :key="currency" :value="currency">
                      {{ currency }}
                    </option>
                  </select>
                  <p v-if="convertedAmount !== null">
                    {{ totalAmount }} UGX = <strong>{{ convertedAmount.toFixed(2) }}</strong> {{ selectedCurrency }}
                  </p>

                  <p v-if="loading">Fetching latest exchange rates...</p>
                  <p v-if="error" class="error">{{ error }}</p>
                </div> -->

                <el-form
                  :model="paymentForm"
                  :rules="rules"
                  label-position="top"
                  ref="paymentForm"
                  class="demo-paymentForm2"
                >

                  <fieldset>
                    <legend>Reservation information:</legend>
                    <article>
                      <div
                        class="mb-2"
                        style="display: flex; justify-content: space-between"
                      >
                        <span style="font-weight: 600; font-size: 1.2em">
                          {{ selectedCurrency }}
                          {{ formatNumber( convertedAmount.toFixed(2)) }}
                          {{ selectedCurrency }}
                        </span>
                        <span style="font-weight: 300; font-size: 1em">
                          /{{ datesDifferenceResults_value }}
                          {{ datesDifferenceResults_value == 1 ? "Night" : "Nights" }}
                        </span>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                            <el-form-item prop="checkInDate">
                              <el-date-picker
                                v-model="paymentForm.checkInDate"
                                type="date"
                                @change="changedValue()"
                                placeholder="CheckIn date"
                                style="width: 100%"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item prop="checkOutDate">
                              <el-date-picker
                                v-model="paymentForm.checkOutDate"
                                type="date"
                                @change="changedValue()"
                                placeholder="CheckOut date"
                                style="width: 100%"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                            <el-form-item label="Adults" prop="numberOfAdults">
                              <el-input
                                number
                                v-model="paymentForm.numberOfAdults"
                                controls-position="right"
                                style="width: 100%"
                                @change="handleChange"
                                placeholder="1"
                                :min="1"
                                :max="100"
                              >
                              </el-input>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item
                              label="Children"
                              prop="numberOfChildren"
                            >
                              <el-input
                                v-model="paymentForm.numberOfChildren"
                                controls-position="right"
                                style="width: 100%"
                                @change="handleChange"
                                placeholder="0"
                                :min="0"
                                :max="10"
                              >
                              </el-input>
                            </el-form-item>
                          </div>
                      </div>
                    </article>
                  </fieldset>


                  <fieldset>
                    <legend>Billing information:</legend>
                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          label="First Name"
                          prop="first_name"
                        >
                          <el-input v-model="paymentForm.first_name" placeholder="First Name"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          label="Last Name"
                          prop="last_name"
                        >
                          <el-input v-model="paymentForm.last_name" placeholder="Last Name"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          label="Email Address"
                          prop="email_address"
                        >
                          <el-input v-model="paymentForm.email_address" type="email" placeholder="Email"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          label="Phone Number"
                          prop="phone_number"
                        >
                          <div>
                            <vue-tel-input v-model="paymentForm.phone_number" @input="handleInput"></vue-tel-input>
                          </div>
                        </el-form-item>

                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          label="Address 1"
                          prop="line_1"
                        >
                          <el-input v-model="paymentForm.line_1" placeholder="Adrress 1"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          label="Address 2"
                          prop="line_2"
                        >
                          <el-input v-model="paymentForm.line_2" placeholder="Adrress 2"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          label="City"
                          prop="city"
                        >
                          <el-input v-model="paymentForm.city" placeholder="City"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          label="State"
                          prop="state"
                        >
                          <el-input v-model="paymentForm.state" placeholder="State"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          label="Postal Code"
                          prop="postal_code"
                        >
                          <el-input v-model="paymentForm.postal_code" placeholder="Postal Code"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          label="Zip Code"
                          prop="zip_code"
                        >
                          <el-input v-model="paymentForm.zip_code" placeholder="Zip Code"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </fieldset>

                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="makeAReservation('paymentForm')"
                    >Proceed To Payment</el-button>
                  </el-form-item>
                </el-form>

                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Acacia Wilderness Mweya, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="paymentForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from 'axios'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  components: {
    VueTelInput,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      isShowPaymentIframe: false,
      paymentIframeLink: "",
      bookings: [],
      selectedBannerPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      checkInDate: "",
      checkOutDate: "",
      datesDifferenceResults_value: "",
      adults_num: 1,
      children_num: 1,
      phone: '',
      phone2: '',
      countryCode: '',
      supported_currencies: [
        { key: 'UGX', value: 'UGX', text: '🇺🇬 UGX' },
        { key: 'KES', value: 'KES', text: '🇰🇪 KES' },
        { key: 'USD', value: 'USD', text: '🇺🇸 USD' },
        { key: 'BIF', value: 'BIF', text: '🇧🇮 BIF' },
        { key: 'RWF', value: 'RWF', text: '🇷🇼 RWF' },
        { key: 'SSP', value: 'SSP', text: '🇸🇸 SSP' },
        { key: 'TZS', value: 'TZS', text: '🇹🇿 TZS' },
      ],

      addUserModal: false,
      isEditBannerModalVisible: false,
      value: "",
      loadingError: false,

      telOptions: {
        mode: "international", // Ensures the country code is included
        autoFormat: true, // Auto-format phone numbers
        dropdownOptions: {
          showFlags: true, // Show country flags
        },
      },


      
      paymentForm: {
        
        pricePerNight: "",
        checkInDate: "",
        checkOutDate: "",
        numberOfAdults: 1,
        numberOfChildren: 0,

        first_name : '',
        last_name : '',
        email_address : '',
        phone_number : '',
        country_code : '',
        line_1: '',
        line_2: '',
        state: '',
        postal_code: '',
        zip_code: ''
      },

      rules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last Name is required",
            trigger: "blur",
          },
        ],
        email_address: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],


        phone_number: [
          {
            required: true,
            message: "Phone Number is required",
            trigger: "blur",
          },
        ],

        country_code: [
          {
            required: false,
          },
        ],
        line_1: [
          {
            required: false,
          },
        ],
        line_2: [
          {
            required: false,
          },
        ],
        state: [
          {
            required: false,
          },
        ],
        postal_code: [
          {
            required: false,
          },
        ],
        zip_code: [
          {
            required: false,
          },
        ],
      },

      
      country: '',
      selectedCurrency: "UGX", // Default selected currency
      exchangeRates: {}, // Stores live exchange rates
      error: null,

    };
  },

  props: {
    showUserEditDialog: {
      required: true,
      type: Boolean,
    },
    occupancyData: {
      required: true,
      type: Object,
    },
  },

  watch: {
    showUserEditDialog() {
      if (this.showUserEditDialog === true) {
        this.isEditBannerModalVisible = true;
      } else {
        this.isEditBannerModalVisible = false;
      }
    },
  },

  computed: {
    totalAmount() {
      return this.datesDifferenceResults_value * this.occupancyData.pricePerNight
    },

    convertedAmount() {
      if (this.selectedCurrency && this.totalAmount > 0 && this.exchangeRates[this.selectedCurrency]) {
        return this.totalAmount * this.exchangeRates[this.selectedCurrency];
      }
      return null;
    },
  },

  mounted() {
    this.datesDifferenceResults();
    this.fetchExchangeRates(); // Fetch rates when the component is mounted
  },

  methods: {
    handleInput(value, { country }) {
      this.phone2 = value;
      this.countryCode = country ? `${country.dialCode}` : '';
      
    },

    handleChange(value) {
      console.log(value);
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    datesDifferenceResults() {
      if (
        this.paymentForm.checkInDate != "" &&
        this.paymentForm.checkOutDate != ""
      ) {
        const date1 = new Date(`${this.paymentForm.checkInDate}`);
        const date2 = new Date(`${this.paymentForm.checkOutDate}`);
        const absDate1 = Math.abs(date1);
        const absDate2 = Math.abs(date2);
        const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
        const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
        const diffDays = ceilDate2 - ceilDate1;
        this.datesDifferenceResults_value = diffDays;
      } else {
        this.datesDifferenceResults_value = 1;
      }
    },

    changedValue() {
      const date1 = new Date(`${this.paymentForm.checkInDate}`);
      const date2 = new Date(`${this.paymentForm.checkOutDate}`);
      const absDate1 = Math.abs(date1);
      const absDate2 = Math.abs(date2);
      const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
      const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
      console.log("In: " + ceilDate1);
      console.log("Out: " + ceilDate2);
      if (ceilDate2 < ceilDate1) {
        this.paymentForm.checkOutDate = "";

        this.$notify({
          categoryName: "Past Date",
          message: "Check out date can't be lower than Check In date.",
          type: "warning",
        });
      }
      this.datesDifferenceResults();
    },

    handleClose(done) {
      done();
      this.$emit("closeEditBannerDialog");
    },

    showAddUserModal() {
      this.addUserModal = true;
      this.getCountries();
    },
    
    async makeAReservation(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true;
           
          try {
            let request = await this.$http.post(
              `reservations`, {
                first_name: this.paymentForm.first_name,
                last_name: this.paymentForm.last_name,
                phone_number: this.phone2,
                email_address: this.paymentForm.email_address,
                line_1: this.paymentForm.line_1,
                line_2: this.paymentForm.line_2,
                country_code: this.countryCode,
                currency: this.selectedCurrency,
                state: this.paymentForm.state,
                city: this.paymentForm.city,
                postal_code: this.paymentForm.postal_code,
                zip_code: this.paymentForm.zip_code,
                
                roomCategoryID: this.occupancyData.categoryID,
                checkInDate: this.paymentForm.checkInDate,
                checkOutDate: this.paymentForm.checkOutDate,
                totalAmount: this.convertedAmount.toFixed(2),
                numberOfAdults: this.paymentForm.numberOfAdults,
                numberOfChildren: this.paymentForm.numberOfChildren,
                specialRequests:"My Special Requests",
              }
            );
            if (
              request.data.success &&
              request.data.message == "RESERVATION_CREATED_SUCCESSFULLY"
            ) {
              const redirectUrl = request.data.data.paymentUrl;
              // window.location.replace(redirectUrl);
              this.isShowPaymentIframe = true;
              this.paymentIframeLink = request.data.data.paymentUrl;

              return this.$notify.success({
                title: 'Success',
                message: 'Reservation created successfully'
              });

            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify.error({
                title: 'Connection failed',
                message: 'A network error occurred, please try again.'
              });
            }
            this.$notify.error({
                title: 'Loading Failed',
                message: 'Unable to place a reservation now, Please try again later'
              });
          } finally {
            this.isLoading = false;
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Unable to place a reservation.'
          });
          return false;
        }
      });
    },

    

    chooseCurrency() {
      this.$store.commit('setCurrentCurrency', this.currentCurrency)
    },
    
    async myCountry() {
      try {
        const request = await axios.get(
          'https://api.ipdata.co/?api-key=5e051ac0f5efa2e17515cecaf23181e39c695b2500c151c98bd3021b'
        )
        if (request.data) {
          this.country = request.data.country_name
          localStorage.setItem('current_country', this.country)
          this.currency_code = request.data.currency.code
          this.isFetching = false
          this.isError = false
        }
      } catch (error) {
        this.isFetching = true
        this.isError = true
      } finally {
        this.loading = false
      }
    },



    async fetchExchangeRates() {
      this.loading = true;
      this.error = null;
      try {
        const response = await fetch("https://api.exchangerate-api.com/v4/latest/UGX");
        if (!response.ok) throw new Error("Failed to fetch exchange rates");
        
        const data = await response.json();
        this.exchangeRates = data.rates; // Update exchange rates
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }

  },
};
</script>

<style scoped>


.currency-converter {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
label {
  display: block;
  margin-top: 10px;
}
input, select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
p {
  margin-top: 10px;
}
.error {
  color: red;
}



  
.iframe-container {
  height: 70vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
}

iframe {
            width: 100%;
            height: 100%;
            border: none; /* Removes the border */
        }
h2 {
  font-size: 1.6em;
  font-weight: 600;
}

fieldset {
    background-color: #f7f3ea;
      border: 1px solid #DDD0A9;
      color: black;
      padding: 20px;
      border-radius: 5px;
      box-shadow: #DDD0A9 0px 1px 2px 0px;
      /* background: powderblue; */
      margin-bottom: 50px;
  }

  fieldset:hover{
      box-shadow: #DDD0A9 0px 3px 8px;
  }

  legend {
    border: 2px solid #DDD0A9;
    background-color: #DDD0A9;
      box-shadow: #DDD0A9 0px 1px 2px 0px;
      border-radius: 5px;
    width: 100%;
      font-size: 18px;
      padding: 20px;
  }
</style>
<style>
.payment_dialogs .el-dialog {
  width: 80%;
}


/* Ensure proper styling with Element UI */
.vue-tel-input {
  width: 100%;
}

.vue-tel-input .vti__dropdown {
  z-index: 9999 !important; /* Ensure dropdown is above other elements */
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .payment_dialogs .el-dialog {
    width: 80%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .payment_dialogs .el-dialog {
    width: 80%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .payment_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .payment_dialogs .el-dialog {
    width: 90%;
  }
} 
</style>
